<template>
	<main>
		<div class="flex justify-center min-h-screen w-full bg-white">
			<div
				class="absolute self-center lg:relative flex flex-1 flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24 z-10">
				<div class="mx-auto w-full max-w-sm lg:w-96">
					<img class="mx-auto h-16 w-auto" src="@/assets/tixby-logo.png" alt="Workflow" />
				</div>
				<div class="mt-8 mx-auto w-full max-w-md lg:w-[30rem]">
					<router-view :key="$route.fullPath" />
				</div>
			</div>
			<div class="relative w-0 flex-1 lg:block bg-gray-500">
				<img class="absolute inset-0 h-full w-full object-cover opacity-100 bg-no-repeat"
					src="https://tixby-public.s3.amazonaws.com/img/party5low.jpg" alt="" />
				<div :style="{
					background: 'rgba(51,51,51,.8)',
					bottom: 0,
					height: '100%',
					left: 0,
					position: 'absolute',
					right: 0,
					top: 0,
					width: '100%',
					zIndex: 0
				}"></div>

			</div>
		</div>
	</main>
</template>
