import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    type: "button",
    class: _normalizeClass(["bg-gray-200 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-md cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500", { 'bg-primary-500': _ctx.modelValue }]),
    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update:modelValue', !_ctx.modelValue))),
    role: "switch",
    "aria-checked": "false"
  }, [
    _createElementVNode("span", {
      "aria-hidden": "true",
      class: _normalizeClass(["translate-x-0 pointer-events-none inline-block h-5 w-5 rounded-md bg-white shadow transform ring-0 transition ease-in-out duration-200", { 'translate-x-5': _ctx.modelValue }]),
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', !_ctx.modelValue)))
    }, null, 2)
  ], 2))
}