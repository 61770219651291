import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["type", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    type: (_ctx.type as any),
    class: _normalizeClass(`
	ml-3
	inline-flex
	justify-center
	py-2
	px-4
	border border-transparent
	shadow-sm
	text-sm
	font-medium
	rounded-none
	text-white
	bg-${_ctx.props.color}-500
	hover:bg-${_ctx.props.color}-600
	focus:outline-none
	focus:ring-2 focus:ring-offset-2 focus:ring-${_ctx.props.color}-500
	disabled:bg-primary-300 disabled:cursor-not-allowed`),
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args))),
    disabled: _ctx.disabled
  }, _toDisplayString(_ctx.props.label), 11, _hoisted_1))
}