
import { defineComponent } from 'vue';

const Button = defineComponent({
	props: { action: Function, type: String, color: String, label: String, disabled: Boolean },
	setup(props) {
		const onClick = () => {
			if (props.action) props.action();
		};

		return { onClick, props };
	},
});

export default Button;
