import {
	createRouter,
	createWebHashHistory,
	NavigationGuardWithThis,
	RouteRecordRaw,
	RouteLocationNormalized,
	NavigationGuardNext,
} from 'vue-router';

import AppSelector from '@/views/AppSelector.vue';
import LoggedInLayout from '@/layouts/LoggedIn.vue';
import LoggedOutLayout from '@/layouts/LoggedOut.vue';
import UsersList from '@/views/users/List.vue';
import RolesList from '@/views/roles/List.vue';
import RoleManagement from '@/views/roles/Manage.vue';
import CompaniesList from '@/views/companies/List.vue';
import UserManagement from '@/views/users/Manage.vue';
import CompanyManagement from '@/views/companies/Manage.vue';
import { getUserData } from '@/api/tix-auth';
import { Application, Role } from '@tixby/core-lib';

const APP_NAME = 'admin';

const authGuard =
	(app?: string) => (to: RouteLocationNormalized, _from: RouteLocationNormalized, next: NavigationGuardNext) => {
		const loggedIn = Boolean(localStorage.getItem('sessionToken')) && Boolean(localStorage.getItem('refreshToken'));

		if (!loggedIn) return next('/login');

		if (localStorage.getItem('rotatePassword') && !to.fullPath.includes('rotate-password')) {
			return next(`/rotate-password?rt=${_from.fullPath}`);
		}

		getUserData().then((user) => {
			to.params.user = user;
			if (!app) next();
			else {
				const hasAccess = Boolean(user?.roles?.find((r: Role) => (r.app as Application).value === app));
				if (!hasAccess) next('/apps');
				else next();
			}
		});
	};

const loginGuard: NavigationGuardWithThis<undefined> = (_to, _from, next) => {
	const loggedIn = Boolean(localStorage.getItem('sessionToken')) && Boolean(localStorage.getItem('refreshToken'));

	if (loggedIn) next('/');
	else next();
};

const routes: Array<RouteRecordRaw> = [
	{
		path: '/',
		component: LoggedInLayout,
		children: [
			{
				path: '/roles',
				name: 'Roles',
				component: RolesList,
				beforeEnter: authGuard(APP_NAME),
			},
			{
				path: '/roles/manage',
				name: 'CreateRole',
				component: RoleManagement,
				props: false,
				beforeEnter: authGuard(APP_NAME),
			},
			{
				path: '/roles/manage/:id',
				name: 'EditRole',
				component: RoleManagement,
				props: true,
				beforeEnter: authGuard(APP_NAME),
			},
			{
				path: '/users',
				name: 'Users',
				component: UsersList,
				beforeEnter: authGuard(APP_NAME),
			},
			{
				path: '/users/manage/:id',
				name: 'EditUser',
				component: UserManagement,
				props: true,
				beforeEnter: authGuard(APP_NAME),
			},
			{
				path: '/users/manage',
				name: 'CreateUser',
				component: UserManagement,
				props: false,
				beforeEnter: authGuard(APP_NAME),
			},
			{
				path: '/companies',
				name: 'Companies',
				component: CompaniesList,
				props: false,
				beforeEnter: authGuard(APP_NAME),
			},
			{
				path: '/companies/manage/:id',
				name: 'EditCompany',
				component: CompanyManagement,
				props: true,
				beforeEnter: authGuard(APP_NAME),
			},
			{
				path: '/companies/manage',
				name: 'CreateCompany',
				component: CompanyManagement,
				props: false,
				beforeEnter: authGuard(APP_NAME),
			},
			{
				path: '/',
				redirect: '/users',
			},
		],
	},
	{
		path: '/',
		component: LoggedOutLayout,
		children: [
			{
				path: '/apps',
				name: 'AppSelector',
				component: AppSelector,
				beforeEnter: authGuard(),
			},
			{
				path: '/login',
				name: 'Login',
				component: () => import('../views/Login.vue'),
				beforeEnter: loginGuard,
			},
			{
				path: '/forgot-password',
				name: 'ForgotPassword',
				component: () => import('../views/ForgotPassword.vue'),
				beforeEnter: loginGuard,
			},
			{
				path: '/reset-password/:token',
				name: 'ResetPassword',
				component: () => import('../views/ResetPassword.vue'),
				beforeEnter: loginGuard,
			},
			{
				path: '/verify/:token',
				name: 'VerifyAccount',
				component: () => import('../views/ResetPassword.vue'),
				beforeEnter: loginGuard,
			},
			{
				path: '/rotate-password',
				name: 'RotatePassword',
				component: () => import('../views/ResetPassword.vue'),
				beforeEnter: authGuard(),
			},
		],
	},
	{
		path: '/logout',
		component: () => import('../views/Logout.vue'),
	},
];

export default createRouter({ history: createWebHashHistory(), routes });
