import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-74b52b7e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "checker" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", {
      class: _normalizeClass({ checked: _ctx.checks.hasUppercase })
    }, "A", 2),
    _createElementVNode("span", {
      class: _normalizeClass({ checked: _ctx.checks.hasLowerCase })
    }, "a", 2),
    _createElementVNode("span", {
      class: _normalizeClass({ checked: _ctx.checks.hasNumber })
    }, "#", 2),
    _createElementVNode("span", {
      class: _normalizeClass({ checked: _ctx.checks.hasSymbol })
    }, "@", 2),
    _createElementVNode("span", {
      class: _normalizeClass({ checked: _ctx.checks.hasMoreThan8Chars })
    }, "+8", 2)
  ]))
}