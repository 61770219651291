
import { defineComponent, onMounted, ref } from 'vue';
import { PencilIcon, PlusIcon } from '@heroicons/vue/24/solid';
import { formatRelative } from 'date-fns';
import { getLogs } from '@/api/tix-auth';
import { UserAuditLog } from '@/types';

const AuditLogs = defineComponent({
	props: ['userId'],
	components: { PencilIcon, PlusIcon },
	setup(props) {
		const logs = ref<UserAuditLog[]>([]);

		onMounted(async () => fetchLogs());

		const fetchLogs = async () => (logs.value = await getLogs(props.userId));

		return { logs, formatRelative, fetchLogs };
	},
});

export default AuditLogs;
