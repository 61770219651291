
import { defineComponent, ref, watch } from 'vue';

const PasswordChecker = defineComponent({
	props: { password: String, modelValue: Boolean },
	setup(props, { emit }) {
		const checks = ref({
			hasUppercase: false,
			hasLowerCase: false,
			hasNumber: false,
			hasSymbol: false,
			hasMoreThan8Chars: false,
		});

		const checkPasswordStrength = (password = '') => {
			checks.value.hasUppercase = new RegExp(/(?=.*[A-Z])/).test(password);
			checks.value.hasLowerCase = new RegExp(/(?=.*[a-z])/).test(password);
			checks.value.hasNumber = new RegExp(/(?=.*[0-9])/).test(password);
			checks.value.hasSymbol = new RegExp(/(?=.*[^A-Za-z0-9])/).test(password);
			checks.value.hasMoreThan8Chars = new RegExp(/(?=.{8,})/).test(password);

			const valid =
				checks.value.hasUppercase &&
				checks.value.hasLowerCase &&
				checks.value.hasNumber &&
				checks.value.hasSymbol &&
				checks.value.hasMoreThan8Chars;

			emit('update:modelValue', valid);
		};

		watch(() => props.password, checkPasswordStrength);

		return { checks };
	},
});

export default PasswordChecker;
