import { Application } from './types';
import {
	ChartPieIcon,
	CodeBracketIcon,
	Cog6ToothIcon,
	PhoneIcon,
	RectangleGroupIcon,
	Square2StackIcon, TicketIcon,
} from '@heroicons/vue/24/solid';

export const ADMIN = process.env.VUE_APP_TIX_ADMIN || '';
export const CORE = process.env.VUE_APP_TIX_CORE || '';
export const PORTAL = process.env.VUE_APP_TIX_PORTAL || '';
export const INTELLIGENCE = process.env.VUE_APP_INTELLIGENCE || '';
export const CALL_CENTER = process.env.VUE_APP_CALL_CENTER || '';

export const getAppUrl = (app: Application) => {
	if (process.env.NODE_ENV !== 'development') return app.url;

	switch (app.value) {
		case 'portal':
			return PORTAL;
		case 'admin':
			return ADMIN;
		case 'callcenter':
			return CALL_CENTER;
		case 'intelligence':
			return INTELLIGENCE;
		default:
			return '';
	}
};

export const getAppIcon = (app: string) => {
	switch (app) {
		case 'portal':
			return RectangleGroupIcon;
		case 'admin':
			return Cog6ToothIcon;
		case 'callcenter':
			return PhoneIcon;
		case 'intelligence':
			return ChartPieIcon;
		case 'dashboard':
			return Square2StackIcon;
		case 'tixby':
			return TicketIcon;
		default:
			return CodeBracketIcon;
	}
};


