
import { defineComponent, ref, watch } from 'vue';
import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue';
import { ExclamationTriangleIcon } from '@heroicons/vue/24/outline';

const PurgeModal = defineComponent({
	components: {
		Dialog,
		DialogOverlay,
		DialogTitle,
		TransitionChild,
		TransitionRoot,
		ExclamationTriangleIcon,
	},
	props: ['show'],
	setup(props, { emit }) {
		const open = ref(props.show);
		const confirmPhrase = ref('');

		watch(
			() => props.show,
			(newVal) => {
				open.value = newVal;
				confirmPhrase.value = '';
			}
		);
		watch(open, (newVal) => {
			if (!newVal) emit('close', newVal);
		});

		return {
			open,
			confirmPhrase,
		};
	},
});

export default PurgeModal;
